<template>
  <section class="max-w-6xl mx-auto p-8" v-if="evaluationData">
    <h1 class="mb-6 h2">Auswertung für Organisation <i>{{ organizationName }}</i></h1>

    <h2 class="h4 no-print">Übersicht über die monatliche Nutzung</h2>
    <Bar class="no-print mb-6"
         :chart-options="chartOptions"
         :chart-data="chartData"
    />

    <div class="float-right inline-flex flex-row justify-end items-center space-x-2">
      <p class="mb-0 whitespace-nowrap">Auswertung vom </p>
      <input type="date" class="form-input" :value="evaluationDuration.start.toISOString().substring(0,10)"
             @change="evaluationDuration.start = new Date($event.target.value)" style="max-width: 150px;">
      <p class="mb-0 whitespace-nowrap"> bis zum </p>
      <input type="date" class="form-input" :value="evaluationDuration.end.toISOString().substring(0,10)"
             @change="evaluationDuration.end = new Date($event.target.value)" style="max-width: 150px;">
    </div>

    <h2 class="h4 no-print">Zeitraumsauswertung</h2>

    <p>In dem Ausgewählten Zeitraum wurden in Summe <strong>{{ dateFilteredQuizzesWithMoreThan5Players.length }} Quizze
      gespielt</strong>. </p>
    <p>Es wurden insgesamt <strong>{{
        removeSlideQuestions(getQuestionsFromQuizzes(dateFilteredQuizzesWithMoreThan5Players)).length
      }} Fragen
      gestellt</strong>, dazu wurden noch
      <strong>{{
          getQuestionsFromQuizzes(dateFilteredQuizzesWithMoreThan5Players).length - removeSlideQuestions(getQuestionsFromQuizzes(dateFilteredQuizzesWithMoreThan5Players)).length
        }} Folien gezeigt</strong>.</p>
    <p>Es wurden insgesamt <strong>{{
        votesForQuestions(getQuestionsFromQuizzes(dateFilteredQuizzesWithMoreThan5Players)).length
      }} Stimmen abgegeben </strong>.</p>

    <h3 class="h5">Auswertung der Fragen der Fragenbibliothek</h3>

    <table class="table mt-8">
      <tr class="font-bold">
        <td>Frage</td>
        <td>Anzahl Quizze > 5 Teilnehmer</td>
        <td>Anzahl Stimmen aus Quizzen > 5 Teilnehmern</td>
        <td>Stimmen pro Antwortoption</td>
      </tr>
      <tr v-for="question in assignQuizzesToQuestionLibrary(dateFilteredQuizzesWithMoreThan5Players)">
        <td>{{ question.title }}</td>
        <td>{{ question.quizzes.length }}</td>
        <td>{{ question.votes.length }}</td>
        <td>
          <ul>
            <li v-for="option in groupVotesForQuestions(question)">
              {{ option.title }}: {{ option.votes }}
            </li>
          </ul>
        </td>
      </tr>
    </table>

  </section>
</template>
<script>
import questionLibrary from "@/assets/questionLibrary.json";
import Chart from 'chart.js/auto';
import { Bar } from 'vue-chartjs'
import { QUESTION_TYPES } from "@/constants/questions";


export default {
  components: {
    Bar
  },
  props: ["evaluationData", "organizationName"],
  data() {
    return {
      evaluationDuration: {
        start: new Date("2022-02-01"),
        end: new Date(Date.now())
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            display: true,
          },
          y: {
            display: true,
            // type: 'logarithmic',
          }
        }
      }
    }
  },
  computed: {
    quizzesWithMoreThan5Players() {
      console.log(this.evaluationData);
      return this.evaluationData.filter(q => q.players.length > 5);
    },
    dateFilteredQuizzesWithMoreThan5Players() {
      return this.quizzesWithMoreThan5Players.filter(q => new Date(q.created) >= this.evaluationDuration.start && new Date(q.created) <= this.evaluationDuration.end);
    },
    quizzesWithLessThan5Players() {
      return this.evaluationData.filter(q => q.players.length <= 5);
    },
    amountOfPlayerOverQuizzesWithMoreThan5Players() {
      return this.quizzesWithMoreThan5Players.reduce((acc, q) => acc + q.players.length, 0);
    },
    numberOfPlayersPerMonth() {
      const months = Array(12).fill(0);

      this.quizzesWithMoreThan5Players.forEach((quiz) => {
        const date = new Date(quiz.created);
        const month = date.getMonth();
        months[month] += quiz.players.length;
      })

      return months;
    },
    numberOfQuizzesWithMoreThan5PlayersPerMonth() {
      const months = Array(12).fill(0);

      this.quizzesWithMoreThan5Players.forEach((quiz) => {
        const date = new Date(quiz.created);
        const month = date.getMonth();
        months[month] += 1;
      })

      return months;
    },
    chartData() {
      const currentMonthIndex = new Date().getMonth() + 1;
      const labels = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
      const shiftedLabels = labels.splice(currentMonthIndex).concat(labels);

      const shiftedPlayers = this.numberOfPlayersPerMonth.splice(currentMonthIndex).concat(this.numberOfPlayersPerMonth);
      const shiftedQuizzesWithMoreThan5Players = this.numberOfQuizzesWithMoreThan5PlayersPerMonth.splice(currentMonthIndex).concat(this.numberOfQuizzesWithMoreThan5PlayersPerMonth);
      const averageParticipantsPerQuiz = shiftedPlayers.map((players, index) => players / shiftedQuizzesWithMoreThan5Players[index]);

      return {
        labels: shiftedLabels,
        datasets: [{
          data: shiftedPlayers,
          label: "Anzahl Teilnehmer über alle Quizze mit mehr als 5 Teilnehmern pro Monat",
          backgroundColor: "lightblue"
        }, {
          data: shiftedQuizzesWithMoreThan5Players,
          label: "Anzahl Quizze mit mehr als 5 Teilnehmern pro Monat",
          backgroundColor: "lightgreen"
        }, {
          data: averageParticipantsPerQuiz,
          label: "Durchschnittliche Teilnehmer pro Quiz pro Monat",
          backgroundColor: "lightcoral"
        }
        ]
      }
    },

  },
  methods: {
    votesForQuestions(questions) {
      return questions.map(q => q.options.map(o => o.votes).flat()).flat();
    },
    removeSlideQuestions(questions) {
      return questions.filter(q => q.type !== QUESTION_TYPES.SLIDE);
    },
    getQuestionsFromQuizzes(quizzes) {
      return quizzes.map(q => q.questions).flat();
    },
    assignQuizzesToQuestionLibrary(quizzes) {
      return questionLibrary.map(question => {
        const title = question.title;
        const quizzesWithQuestion = quizzes.filter(q => q.questions.filter(q => q.title === title).length > 0);
        return {
          ...question,
          quizzes: quizzesWithQuestion,
          votes: quizzesWithQuestion.map(q => q.questions.filter(q => q.title === title).map(q => q.options.map(o => o.votes).flat()).flat()).flat()
        }
      }).sort((a, b) => b.quizzes.length - a.quizzes.length);
    },
    groupVotesForQuestions(question) {
      return question.options.map(o => {
        return {
          title: o,
          votes: question.votes.filter(v => v.option === o).length
        }
      }).flat();
    }
  }
}
</script>
<style>
@media print {
  .no-print {
    display: none !important;
  }
}

@media print {
  @page {
    size: landscape
  }
}
</style>