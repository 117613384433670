<template>
  <evaluation-content v-if="evaluationData" :evaluation-data="evaluationData" :organization-name="organizationName"></evaluation-content>
  <div v-else class="mx-auto max-w-lg text-center">
    <spinner class="my-8"/>
    Laden aller Daten... <br>
    Dies kann bis zu einer Minute in Anspruch nehmen.
  </div>
</template>
<script>
import { getEvaluationData, organizationCollection } from "@/plugins/firebase";
import EvaluationContent from "@/components/EvaluationContent.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
    EvaluationContent
  },
  data() {
    return {
      evaluationData: null,
      organizationName: ""
    }
  },
  created() {
    getEvaluationData({ organizationID: this.$route.params.id }).then(data => {
      this.evaluationData = data.data;
    });

    organizationCollection.doc(this.$route.params.id).get().then(doc => {
      this.organizationName = doc.data().name;
    });
  }
}

</script>